import { FC } from 'react';
import { PictureContainer } from './styles';
import { TPictureProps } from './types';

export const PictureField: FC<TPictureProps> = ({ url }) => (
  <PictureContainer>
    <div className="pictureWrapper">
      <div className="pictureContainer">
        <img src={url} alt="Actual mascot" />
      </div>
    </div>
  </PictureContainer>
);
