import { FC } from 'react';
import { DropdownContainer } from './styles';
import { TDropdownProps } from './types';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { questionTextByFormType } from 'utils/questionTextByFormType';
// eslint-disable-next-line import/no-extraneous-dependencies
import Select from 'react-select';
import { IFormStateV2 } from 'pages/form/types';
import { useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';

export const DropdownField: FC<TDropdownProps> = ({
  id,
  value,
  name,
  question,
  answerPositionV2,
  error,
  hideError = false,
  options,
  setFieldValue,
}) => {
  const { isPageByPageForm } = useFormSettings();
  const { touched, errors } = useFormikContext<IFormStateV2>();
  const handleChange = (value: any) => {
    setFieldValue(name, value);
  };

  const renderQuestion = () => question && (
  <div className="titleContainer">
    <h3>
      {questionTextByFormType(
        isPageByPageForm,
        answerPositionV2 ?? 0,
        question,
      )}
    </h3>
  </div>
  );

  const renderInput = () => (
    <div className="inputRaw">
      <Select
        value={value}
        defaultValue={value}
        onChange={handleChange}
        options={options}
        placeholder="Оберіть"
        classNames={{
          control: (state) => (state.isFocused ? 'control-focused' : 'control'),
          container: (state) => (state.isFocused ? 'container-focused' : 'container'),
          placeholder: (state) => (state.isFocused ? 'placeholder' : 'placeholder'),
          singleValue: () => 'singleValue',
          indicatorSeparator: (state) => (state.isFocused ? 'separator' : 'separator'),
          option: (state) => (state.isSelected
            ? 'option-selected'
            : state.isFocused
              ? 'option-focused'
              : 'option'),
        }}
      />
    </div>
  );

  const renderError = () => touched && (
  <FormErrorMessage>
    <div className="formErrorContainer">
      <p>{errors?.extraFields?.[`${id.toString()}|||s`]?.selectedOption}</p>
    </div>
  </FormErrorMessage>
  );

  return (
    <DropdownContainer>
      <div className="dropdownWrapper">
        {renderQuestion()}
        <div className="inputContainer">{renderInput()}</div>
        {renderError()}
      </div>
    </DropdownContainer>
  );
};
