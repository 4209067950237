import { FileUploaded } from 'api/entities_v2/ApiFormV2';
import { fileApi } from 'api/fileApi/FileApi';
import { isArray } from 'lodash';
import { IFormFieldV2, IResponseAnswer } from 'pages/form/types';

async function readFiles(files: { value: File; key: string }[]) {
  const res: { data: FileUploaded }[] = await Promise.all(
    files.map((file) => fileApi.sendFile(file?.value)),
  );
  return res.map((e) => e.data.url);
}

export const mapFormStateToFormData = async (fields: IFormFieldV2[]) => {
  try {
    const fieldsArrayRequests: Promise<IResponseAnswer[]> = Promise.all(
      fields
        .filter(
          (e) => !['caption', 'button', 'picture', 'paragraph'].includes(e.type),
        )
        .filter((e) => {
          const selectedCheckbox = e.answers.some((e) => e.selected);
          return !!e.selectedOption || !!e.answer || selectedCheckbox;
        })
        .map<Promise<IResponseAnswer>>(async (field) => {
          switch (field?.type) {
            case 'file_upload':
              return new Promise<IResponseAnswer>((resove, reject) => {
                readFiles(field.answer)
                  .then((files) => {
                    resove({ fieldID: field.id, value: files });
                  })
                  .catch((e) => reject(e));
              });
            case 'radio':
              return {
                fieldID: field.id,
                value: field.selectedOption,
              };
            case 'connection_channel':
              return {
                fieldID: field.id,
                value: field.selectedOption,
              };
            case 'dropdown':
              return {
                fieldID: field.id,
                // @ts-ignore
                value: field.selectedOption?.value,
              };
            case 'checkbox':
              // eslint-disable-next-line no-case-declarations
              const ans = field.answers
                .filter((ans: any) => ans.selected)
                .map((e) => e.text)
                .filter((e) => e.length);
              return {
                fieldID: field.id,
                value: [
                  // @ts-ignore
                  ...(ans ?? []),
                  // @ts-ignore
                  ...(ans.length && field.answerDescription[0].length
                    ? field.answerDescription
                    : []),
                ],
              };
            // case 'scale':
            case 'nps':
            case 'csat':
            case 'ces':
              return {
                fieldID: field.id,
                value: `${field.answer}/${
                  field.answers[field.answers.length - 1].text
                }`,
              };
            case 'detailed_response':
            case 'quantity':
            case 'name':
            case 'phone':
            case 'email':
            case 'date':
            case 'extra_question':
            case 'hidden':
            default:
              return { fieldID: field.id, value: field.answer };
          }
        }),
    );
    const answers: IResponseAnswer[] = (await fieldsArrayRequests).filter(
      (e) => !!e?.value?.length,
    );
    const requestAnswers = [];
    for (const answer of answers) {
      if (isArray(answer.value)) {
        answer.value.forEach((val) => requestAnswers.push({ fieldID: answer.fieldID, value: val }));
      } else {
        requestAnswers.push({ fieldID: answer.fieldID, value: answer.value });
      }
    }
    return requestAnswers;
  } catch (e) {
    console.log(e);
  }
};
