import React, { FC, useState } from 'react';
import { CloseModalIcon } from 'assets/icons';
import { translations } from 'i18n/translations';
import { TModalGoogle } from './types';
import { ModalGoogleStyles } from './ModalGoogleStyles';
import { useLanguage } from 'hooks/language/useLanguage';

export const ModalGoogle: FC<TModalGoogle> = ({
  onClose,
  placeId,
  sessionId,
  formId,
  companyId,
  nodeId,
}) => {
  const language = useLanguage().language?.name ?? 'UA';

  const [loading, setLoading] = useState<boolean>(false);

  const handleRedirect = () => {
    // if (!loading) {
    //   setLoading(true);
    //   formApi
    //     .googleTransition({
    //       sessionID: sessionId,
    //       companyID: companyId,
    //       nodeID: nodeId,
    //       formID: formId,
    //     })
    //     .then((res) => {
    //       window.open(
    //         `https://search.google.com/local/writereview?placeid=${placeId}&lsig=0`,
    //         '_self',
    //       );
    //       setLoading(false);
    //     });
    // }
  };

  return (
    <ModalGoogleStyles>
      <div className="modalBody">
        <div className="alert-card">
          <div className="alert-card__title">
            {translations[language].google_modal}
          </div>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button onClick={onClose} className="alert-card__close" type="button">
            <CloseModalIcon />
          </button>
          <div onClick={handleRedirect} className="alert-card__btn">
            {translations[language].yes}
          </div>
        </div>
      </div>
    </ModalGoogleStyles>
  );
};
