import React from 'react';

export function DocumentIcon({ width = 512, height = 512, color = '#000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 384 512"
    >
      <path
        fill={color}
        d="M256 0v128h128L256 0zm-32 128V0H48C21.49 0 0 21.49 0 48v416c0 26.5 21.49 48 48 48h288c26.51 0 48-21.49 48-48V160H256.9c-18.6 0-32.9-14.3-32.9-32zm48 288H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h160c8.836 0 16 7.162 16 16 0 8.8-7.2 16-16 16zm0-64H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h160c8.836 0 16 7.162 16 16 0 8.8-7.2 16-16 16zm16-80c0 8.8-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h160c8.8 0 16 7.2 16 16z"
      />
    </svg>
  );
}
