import React, { useEffect } from 'react';
import { DocumentStyles } from './DocumentStyles';
import { SquareCheckbox } from '../squareCheckbox/SquareCheckbox';
import { useAppDispatch } from 'state/hooks';
import { setDocumentTermsAgreed } from 'state/slices/formSlice';
import { IDocumentProps } from './types';

export function Document({
  field,
  index,
  setTermsAgreed,
  handleChange,
  setFieldValue,
}: IDocumentProps) {
  const dispatch = useAppDispatch();

  function getValueFromOptions(key: string) {
    return field.options?.find(
      (option: { key: string; value: string }) => option.key === key,
    )?.value;
  }

  const name = getValueFromOptions('file_description');
  const url = getValueFromOptions('file_url');
  const documentDescription = getValueFromOptions('description');
  // extraFields[${field.id}|||s].answers
  // useEffect(() => {
  //   if (setTermsAgreed && setFieldValue) {
  //     setFieldValue(`extraFields[${index}].selected`, setTermsAgreed);
  //     dispatch(
  //       setDocumentTermsAgreed({ selected: setTermsAgreed, id: field.id }),
  //     );
  //   }
  // }, [setTermsAgreed]);
  return (
    <DocumentStyles>
      <div className="documentsContainer">
        <h3 className="documentDescription">{documentDescription}</h3>
        <SquareCheckbox
          key={field.id}
          showError
          name={`extraFields[${index}].selected`}
          value={field.selected!}
          onChange={(event) => {
            setFieldValue(
              `extraFields[${index}].selected`,
              event.target.checked,
            );
            // dispatch(
            //   setDocumentTermsAgreed({
            //     selected: event.target.checked,
            //     id: field.id,
            //   }),
            // );
          }}
        >
          {field.question}{' '}
          <a href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </SquareCheckbox>
      </div>
    </DocumentStyles>
  );
}
