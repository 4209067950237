import styled from 'styled-components';

export const ParagraphContainer = styled.div<{
  fontFamily?: string;
  isFontWeightBold?: boolean;
}>`
  .pictureWrapper {
    display: flex;
    justify-content: center;

    .pictureContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 21px;

      p {
        font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto'}, sans-serif`};
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        color: #202020;
        font-weight: 700; // ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
      }
    }
  }
`;
