import React from 'react';
import { ErrorPageStyles } from './ErrorPageStyles';
import { LogoIcon } from 'assets/icons';
import errorImage from 'assets/images/error400.png';
import { useParams } from 'react-router-dom';

function ErrorPage() {
  const { errorCode } = useParams();

  return (
    <ErrorPageStyles>
      <div className="logoContainer">
        <LogoIcon />
      </div>
      <div className="contentContainer">
        <div className="errorContainer">
          <div className="errorImageContainer">
            <img src={errorImage} alt="Error" />
          </div>
          <div className="errorTextContainer">
            <span>{errorCode || 404}</span>
            <span>Bad Request</span>
            <span>Oops! Page you are looking for does not exist</span>
          </div>
        </div>
      </div>
    </ErrorPageStyles>
  );
}

export default ErrorPage;
