import * as Yup from 'yup';
import { every, some, intersection } from 'lodash';
import { translations } from '../../i18n/translations';
import { handleDidScaleValueChanged } from '../../utils';
import { TAnswerField } from 'entities/IExtraField';

export interface IFormField {
  type: TAnswerField;
  required: boolean;
  answer: string;
}
export const phoneOperatorCodesUkraine: string[] = [
  '39',
  '67',
  '68',
  '96',
  '97',
  '98',
  '50',
  '66',
  '95',
  '99',
  '63',
  '73',
  '93',
  '91',
  '92',
  '89',
  '94',
];
export const countryCodes: string[] = ['ua'];

function validateNameValue(val: any, ctx: any) {
  const context = ctx?.from[1]?.value;

  const selectedOption = context?.selectedOption;

  const isOneOfNetworks = ['telegram', 'call', 'viber', 'sms'].includes(
    selectedOption,
  );

  if (selectedOption === 'without answer' || selectedOption === 'email') {
    return true;
  }

  if (isOneOfNetworks) {
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (selectedNetworkObject?.nameInputRequired) {
      if (selectedNetworkObject?.name?.length > 0) {
        return true;
      }
      return false;
    }
    return true;
  }

  return false;
}

function validateOneCheckboxChosen(this: any) {
  const { parent } = this;
  const isSomeCheckboxChosen = some(parent?.answers, ['selected', true]);
  if (isSomeCheckboxChosen) {
    return true;
  }

  return false;
}

function validateOneNetworkChosen(this: any) {
  const { parent } = this;
  const networksOptions = parent?.answer?.map((item: any) => item.key);
  const isSomeCheckboxChosen = networksOptions?.includes(
    parent?.selectedOption,
  );
  if (isSomeCheckboxChosen) {
    return true;
  }
  return false;
}

function validatePhoneOperatorCode(val: any, ctx: any, countryCode: string) {
  const context = ctx?.from[1]?.value;
  const selectedOption = context?.selectedOption;
  const isOneOfNetworks = ['telegram', 'call', 'viber', 'sms'].includes(
    selectedOption,
  );

  if (selectedOption === 'without answer' || selectedOption === 'email') {
    return true;
  }

  if (isOneOfNetworks && countryCodes.includes(countryCode)) {
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (
      !selectedNetworkObject.phoneInputRequired
      || !context.countryCodeNotEditable
    ) {
      return true;
    }
    const enteredOperatorCode = selectedNetworkObject?.value?.substring(3, 5);
    const isCodeExist = phoneOperatorCodesUkraine.includes(enteredOperatorCode);
    if (isCodeExist) {
      return true;
    }
    return false;
  }

  if (isOneOfNetworks && !countryCodes.includes(countryCode)) {
    return true;
  }

  return false;
}

function validatePhoneNumber(value: any, ctx: any) {
  const context = ctx?.from[1]?.value;
  const selectedOption = context?.selectedOption;
  const isOneOfNetworks = [
    'telegram',
    'call',
    'viber',
    'whatsapp',
    'sms',
  ].includes(selectedOption);

  if (selectedOption === 'without answer' || selectedOption === 'email') {
    return true;
  }

  if (isOneOfNetworks) {
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (selectedNetworkObject?.phoneInputRequired) {
      if (selectedNetworkObject?.value?.length > 0) {
        return true;
      }
      return false;
    }
    return true;
  }

  return false;
}

function checkCommentField(val: any, ctx: any) {
  const context = ctx?.parent;
  if (context?.required) {
    const selectedAnswers = context?.answers
      ?.filter((item: any) => item.selected)
      .map((item: any) => item.text);
    const leaveCommentAnswers = context?.answersWithCommentRequired
      ?.filter((item: any) => item.leaveCommetRequired === 'true')
      .map((item: any) => item.option);
    const isInclude = intersection(leaveCommentAnswers, selectedAnswers);
    const answerDescriptionFor = context?.answerDescriptionFor;
    const answerDescription = context?.answerDescription;
    const indexes: number[] = [];
    for (const val of isInclude) {
      const index = answerDescriptionFor.findIndex((item: any) => item === val);
      if (index >= 0) {
        indexes.push(index);
      }
    }
    const values: string | undefined[] = [];
    for (let i = 0; i < indexes.length; i += 1) {
      const value = answerDescription[indexes[i]];
      values.push(value);
    }
    // @ts-ignore
    if (values?.includes(undefined) || values?.includes('')) {
      return false;
    }
    return true;
  }
  return true;
}

function validateQuantityNumber(val: any, ctx: any, lang: string) {
  const { parent } = ctx;
  const valueAsNumber = Number(val);
  const maxValue = parent?.quantityMaxValue;
  const minValue = parent?.quantityMinValue;

  if (valueAsNumber) {
    if (valueAsNumber <= maxValue && valueAsNumber >= minValue) {
      return true;
    }

    return ctx.createError({
      message: `* ${
        translations[lang].validate_quantity_number
          ? translations[lang].validate_quantity_number
          : translations.EN.validate_quantity_number
      } ${minValue} ${
        translations[lang].till_text
          ? translations[lang].till_text
          : translations.EN.till_text
      } ${maxValue || ''}`,
    });
  }
  return true;
}

function checkRadioCommentField(val: any, ctx: any) {
  const context = ctx?.parent;

  if (context?.required) {
    const selectedOption = context?.selectedOption;
    const leaveCommentAnswers = context?.answersWithCommentRequired
      ? context?.answersWithCommentRequired
        ?.filter((item: any) => item.leaveCommetRequired === 'true')
        .map((item: any) => item.option)
      : [];
    const isInclude = leaveCommentAnswers.includes(selectedOption);
    const answerDescription = context?.answerDescription;
    const answerDescriptionFor = context?.answerDescriptionFor;

    let value: string | undefined;
    if (isInclude) {
      const indexOfSelectedOption = answerDescriptionFor?.findIndex(
        (item: any) => item === selectedOption,
      );
      value = answerDescription[indexOfSelectedOption];
    }

    if (isInclude) {
      if (value === undefined || value === '') {
        return false;
      }
      return true;
    }
  }
  return true;
}

function validateAdditionlaField(val: any, ctx: any) {
  const parent = ctx?.parent;

  if (parent?.required) {
    if (parent?.isAdditionalFieldShown && !val) {
      return false;
    }
    return true;
  }

  return true;
}

function validateScaleColorField(val: any, ctx: any) {
  const parent = ctx?.parent;
  const isDefaultScaleValueWasChanged = handleDidScaleValueChanged(parent);
  if (isDefaultScaleValueWasChanged) {
    return true;
  }

  return false;
}

function validatePhoneNumberLength(val: any, ctx: any, countryCode: string) {
  const context = ctx?.from[1]?.value;
  const selectedOption = context?.selectedOption;

  if (selectedOption === 'without answer' || selectedOption === 'email') {
    return true;
  }

  if (countryCodes.includes(countryCode) && !context?.defaultPhoneCode) {
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );

    if (selectedNetworkObject && selectedNetworkObject?.phoneInputRequired) {
      if (selectedNetworkObject?.value?.length === 12) {
        return true;
      }
      return false;
    }
    return true;
  }

  if (!countryCodes.includes(countryCode) || !!context?.defaultPhoneCode) {
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (selectedNetworkObject && selectedNetworkObject?.phoneInputRequired) {
      if (selectedNetworkObject?.value?.length >= 10) {
        return true;
      }
      return false;
    }
    return true;
  }

  return false;
}

function validateOnlyPhoneNumberLength(
  val: any,
  ctx: any,
  countryCode: string,
) {
  const context = ctx?.from[1]?.value;
  const required = (Object.values(context) as any).find(
    (field: any) => field?.type === 'phone_number',
  );

  if (countryCodes.includes(countryCode)) {
    if (required?.required) {
      if (val && val.length === 12) {
        return true;
      }
      return false;
    }
    return true;
  }

  if (!countryCodes.includes(countryCode)) {
    if (required?.required) {
      if (val && val.length >= 10) {
        return true;
      }
      return false;
    }
    return true;
  }

  return false;
}

function validateOnlyPhoneNumberOperatorCode(
  val: any,
  ctx: any,
  countryCode: string,
) {
  const context = ctx?.from[1]?.value;
  const required = (Object.values(context) as any).find(
    (field: any) => field?.type === 'phone',
  );

  if (countryCodes.includes(countryCode) && required.countryCodeNotEditable) {
    const enteredOperatorCode = required.answer?.substring(3, 5);
    const isCodeExist = phoneOperatorCodesUkraine.includes(enteredOperatorCode);
    if (isCodeExist) {
      return true;
    }
    return false;
  }

  return true;
}

function validateEmailField(value: any, ctx: any) {
  const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (value) {
    if (emailRegex.test(value.toLowerCase())) {
      return true;
    }
    return false;
  }

  return true;
}

function validateEmailForRequiredNetwork(value: any, ctx: any) {
  const context = ctx?.from[1]?.value;
  const selectedOption = context?.selectedOption;

  if (selectedOption === 'email') {
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (selectedNetworkObject.value) {
      if (emailRegex.test(selectedNetworkObject?.value?.toLowerCase())) {
        return true;
      }
      return false;
    }
    return false;
  }

  return true;
}

function validateEmailForUnrequiredNetwork(value: any, ctx: any) {
  const context = ctx?.from[1]?.value;
  const selectedOption = context?.selectedOption;

  if (selectedOption === 'email') {
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const selectedNetworkObject = context?.answer?.find(
      (item: any) => item.key === selectedOption,
    );
    if (selectedNetworkObject.value) {
      if (emailRegex.test(selectedNetworkObject?.value?.toLowerCase())) {
        return true;
      }
      return false;
    }
    return true;
  }

  return true;
}

// eslint-disable-next-line default-param-last
export const fieldsValidationByLang = (
  countryCode: string,
  lang: string = 'EN',
) => {
  const commonFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string().required(
        `* ${
          translations[lang].validation_required
            ? translations[lang].validation_required
            : translations.EN.validation_required
        }`,
      ),
      otherwise: Yup.string().nullable(),
    }),
  });

  const emailFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string()
        .required(
          `* ${
            translations[lang].validation_required
              ? translations[lang].validation_required
              : translations.EN.validation_required
          }`,
        )
        .test({
          name: 'is-email-correct',
          message: `* ${
            translations[lang].validation_email
              ? translations[lang].validation_email
              : translations.EN.validation_email
          }`,
          test: validateEmailField,
        }),
      otherwise: Yup.string().test({
        name: 'is-email-correct',
        message: `* ${
          translations[lang].validation_email
            ? translations[lang].validation_email
            : translations.EN.validation_email
        }`,
        test: validateEmailField,
      }),
    }),
  });

  const phoneNumberFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string()
        .required(
          `* ${
            translations[lang].validation_required
              ? translations[lang].validation_required
              : translations.EN.validation_required
          }`,
        )
        .test({
          name: 'is-correct-phone-number-length',
          message: `* ${
            translations[lang].validation_phone_number_length
              ? translations[lang].validation_phone_number_length
              : translations.EN.validation_phone_number_length
          }`,
          test: (value, ctx) => validateOnlyPhoneNumberLength(value, ctx, countryCode),
        })
        .test({
          name: 'is-correct-code',
          message: `* ${
            translations[lang].validation_mobile_code_operator
              ? translations[lang].validation_mobile_code_operator
              : translations.EN.validation_mobile_code_operator
          }`,
          test: (value, ctx) => validateOnlyPhoneNumberOperatorCode(value, ctx, countryCode),
        }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const extraQuestionFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    additionalFieldAnswer: Yup.string().test({
      name: 'is-filled-in',
      test: validateAdditionlaField,
    }),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string().required(
        `* ${
          translations[lang].validation_required
            ? translations[lang].validation_required
            : translations.EN.validation_required
        }`,
      ),
      otherwise: Yup.string().nullable(),
    }),
  });

  const checkboxFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answers: Yup.array().when('required', {
      is: true,
      then: Yup.array()
        .of(Yup.object())
        .test({
          name: 'custom',
          message: `* ${
            translations[lang].validation_at_least_1_option
              ? translations[lang].validation_at_least_1_option
              : translations.EN.validation_at_least_1_option
          }`,
          test: validateOneCheckboxChosen,
        })
        .test({
          name: 'check-answerDescription',
          message: `* ${
            translations[lang].validation_fill_comment
              ? translations[lang].validation_fill_comment
              : translations.EN.validation_fill_comment
          }`,
          test: checkCommentField,
        }),
      otherwise: Yup.array(),
    }),
    answerDescription: Yup.array<String>(),
  });

  const radioFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    selectedOption: Yup.string().when('required', {
      is: true,
      then: Yup.string()
        .required(
          `* ${
            translations[lang].validation_choose_one_from_several_options
              ? translations[lang].validation_choose_one_from_several_options
              : translations.EN.validation_choose_one_from_several_options
          }`,
        )
        .test({
          name: 'is-comment-field-fill',
          message: `* ${
            translations[lang].validation_fill_comment
              ? translations[lang].validation_fill_comment
              : translations.EN.validation_fill_comment
          }`,
          test: checkRadioCommentField,
        }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const scaleFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string().test({
        name: 'selected',
        message: `* ${
          translations[lang].validation_required
            ? translations[lang].validation_required
            : translations.EN.validation_required
        }`,
        test: (val) => val !== undefined && +val > 0,
      }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const scaleColorFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string().test({
        name: 'selected',
        message: `* ${
          translations[lang].validation_choose_mark
            ? translations[lang].validation_choose_mark
            : translations.EN.validation_choose_mark
        }`,
        test: validateScaleColorField,
      }),
      otherwise: Yup.string().nullable(),
    }),
  });

  // const scaleColorFieldSchema = Yup.object().shape({
  //   required: Yup.boolean(),
  //   answer: Yup.string().when('required', {
  //     is: true,
  //     then: Yup.string().test({
  //       name: 'selected',
  //       message: `* ${translations[lang].validation_choose_mark ? translations[lang].validation_choose_mark : translations.EN.validation_choose_mark}`,
  //       test: (val) => val !== undefined && +val >= 0,
  //     }),
  //     otherwise: Yup.string().nullable(),
  //   }),
  // });

  const scaleNpsFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string().test({
        name: 'selected',
        message: `* ${
          translations[lang].validation_choose_mark
            ? translations[lang].validation_choose_mark
            : translations.EN.validation_choose_mark
        }`,
        test: (val) => val !== undefined && +val >= 0,
      }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const minOneByTypeSchema = Yup.object().shape({
    required: Yup.boolean(),
    // TODO: change answer schema from object to array and add validation
    answer: Yup.array().when('required', {
      is: true,
      then: Yup.array()
        .of(
          Yup.lazy((obj: Object | string | undefined) => (typeof obj === 'string' ? Yup.string() : Yup.object())) as any,
        )
        .min(
          1,
          `* ${
            translations[lang].validation_required
              ? translations[lang].validation_required
              : translations.EN.validation_required
          }`,
        )
        .test({
          name: 'one-true',
          message: `* ${
            translations[lang].validation_at_least_1_file
              ? translations[lang].validation_at_least_1_file
              : translations.EN.validation_at_least_1_file
          }`,
          test: (val) => !every(val, ['value', '' || undefined]),
        }),
      otherwise: Yup.array(),
    }),
  });

  // const minOneByTypeSchema2 = Yup.object().shape({
  //   required: Yup.boolean(),
  //   selectedOption: Yup.string().when(['required'], (required, schema) => (required
  //     ? schema.required(
  //       `* ${
  //         translations[lang].validation_choose_one_network
  //           ? translations[lang].validation_choose_one_network
  //           : translations.EN.validation_choose_one_network
  //       }`,
  //     )
  //     : schema.nullable())),
  //   answer: Yup.array().when(['required'], (required, schema) => (required
  //     ? schema
  //       .of(
  //         Yup.object().shape({
  //           nameInputRequired: Yup.boolean(),
  //           phoneInputRequired: Yup.boolean(),
  //           key: Yup.string(),
  //           value: Yup.string()
  //             .test({
  //               name: 'is-appropriate',
  //               message: `* ${
  //                 translations[lang].validation_required
  //                   ? translations[lang].validation_required
  //                   : translations.EN.validation_required
  //               }`,
  //               test: validatePhoneNumber,
  //             })
  //             .test({
  //               name: 'is-correct-code',
  //               message: `* ${
  //                 translations[lang].validation_mobile_code_operator
  //                   ? translations[lang].validation_mobile_code_operator
  //                   : translations.EN.validation_mobile_code_operator
  //               }`,
  //               test: (value, ctx) => validatePhoneOperatorCode(value, ctx, countryCode),
  //             })
  //             .test({
  //               name: 'is-correct-phone-number-length',
  //               message: `* ${
  //                 translations[lang].validation_phone_number_length
  //                   ? translations[lang].validation_phone_number_length
  //                   : translations.EN.validation_phone_number_length
  //               }`,
  //               test: (value, ctx) => validatePhoneNumberLength(value, ctx, countryCode),
  //             })
  //             .test({
  //               name: 'is-email-correct',
  //               message: `* ${
  //                 translations[lang].validation_email
  //                   ? translations[lang].validation_email
  //                   : translations.EN.validation_email
  //               }`,
  //               test: validateEmailForRequiredNetwork,
  //             }),
  //           name: Yup.string().test({
  //             name: 'is-name-entered',
  //             message: `* ${
  //               translations[lang].validation_required
  //                 ? translations[lang].validation_required
  //                 : translations.EN.validation_required
  //             }`,
  //             test: validateNameValue,
  //           }),
  //         }),
  //       )
  //       .test({
  //         name: 'is-one-network-chosen',
  //         message: `* ${
  //           translations[lang].validation_choose_one_network
  //             ? translations[lang].validation_choose_one_network
  //             : translations.EN.validation_choose_one_network
  //         }`,
  //         test: validateOneNetworkChosen,
  //       })
  //     : schema.of(
  //       Yup.object().shape({
  //         nameInputRequired: Yup.boolean(),
  //         phoneInputRequired: Yup.boolean(),
  //         key: Yup.string(),
  //         value: Yup.string().test({
  //           name: 'is-email-correct',
  //           message: `* ${
  //             translations[lang].validation_email
  //               ? translations[lang].validation_email
  //               : translations.EN.validation_email
  //           }`,
  //           test: validateEmailForUnrequiredNetwork,
  //         }),
  //         name: Yup.string(),
  //       }),
  //     ))),
  // });

  const quantityFieldSchema = Yup.object().shape({
    required: Yup.boolean(),
    answer: Yup.string().when('required', {
      is: true,
      then: Yup.string()
        .required(
          `* ${
            translations[lang].validation_required
              ? translations[lang].validation_required
              : translations.EN.validation_required
          }`,
        )
        .test({
          name: 'is-number-in-range',
          test: (val, ctx) => validateQuantityNumber(val, ctx, lang),
        }),
      otherwise: Yup.string().test({
        name: 'is-number-in-range',
        test: (val, ctx) => validateQuantityNumber(val, ctx, lang),
      }),
    }),
  });

  const documentValidation = Yup.object().shape({
    required: Yup.boolean(),
    selected: Yup.boolean().when('required', {
      is: true,
      then: Yup.boolean().oneOf(
        [true],
        `* ${
          translations[lang].validation_agree_the_terms
            ? translations[lang].validation_agree_the_terms
            : translations.EN.validation_agree_the_terms
        }`,
      ),
      otherwise: Yup.boolean(),
    }),
  });

  const dropdownSchema = Yup.object().shape({
    required: Yup.boolean(),
    selectedOption: Yup.object().when(['required'], (required, schema) => (required
      ? schema.required(
        `* ${
          translations[lang].validation_choose_one_from_several_options
            ? translations[lang].validation_choose_one_from_several_options
            : translations.EN.validation_choose_one_from_several_options
        }`,
      )
      : schema.nullable())),
  });

  return {
    // title: Yup.object(),
    name: commonFieldSchema,
    email: emailFieldSchema,
    phone: phoneNumberFieldSchema,
    extra_question: extraQuestionFieldSchema,
    detailed_response: extraQuestionFieldSchema,
    checkbox: checkboxFieldSchema,
    radio: radioFieldSchema,
    scale_color: scaleColorFieldSchema,
    scale_number: scaleFieldSchema,
    scale_star: scaleFieldSchema,
    nps: scaleNpsFieldSchema,
    ces: scaleNpsFieldSchema,
    csat: scaleNpsFieldSchema,
    quantity: quantityFieldSchema,
    scale: quantityFieldSchema,
    dropdown: dropdownSchema,
    date: commonFieldSchema,
    // caption: undefined,
    // picture: undefined,
    // TODO: add validation
    file_upload: minOneByTypeSchema,
    connection_channel: Yup.object().shape({
      required: Yup.boolean(),
      selectedOption: Yup.string().when(['required'], (required, schema) => (required
        ? schema.required(
          `* ${
            translations[lang].validation_choose_one_network
              ? translations[lang].validation_choose_one_network
              : translations.EN.validation_choose_one_network
          }`,
        )
        : schema.nullable())),
    }),
    confirmation: documentValidation,
  };
};
