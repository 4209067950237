import { FormState } from './types';

export const initialState: FormState = {
  formStyles: {
    inputPlaceholderColor: '',
    isFontWeightBold: false,
    isTransparentBg: false,
  },
  formFontData: { fontFamily: 'Roboto' },
  elementsColor: '#0E9285',
  notCheckedElementsColor: '#E5E5E5',
  fields: [],
  language: '',
  formRadioButtonStyles: {
    radioBtnBorderWidth: '1',
    checkedRadioBtnInnerRoundSizes: '12',
  },
  formSettings: {
    countryCode: 'ua',
  },
};
