import { IDocument, IExtraField } from '../entities/IExtraField';
import { ApiAnswer } from './entities/ApiForm';
import { ApiLanguage } from './entities/ApiLanguage';
import { ILanguage } from '../entities/ILanguage';
import { IApiField } from './entities_v2/ApiFormV2';
import { mapApiExtraFieldToIExtraField } from 'adapters/form/fields';
import { mapApiDocumentToIDocument } from 'adapters/form/documents';

export interface ICheckboxAnswer extends ApiAnswer {
  selected?: boolean;
}

export function mapApiFieldsToIExtraFields(
  apiExtraFields: IApiField[],
): IExtraField[] {
  return apiExtraFields.map((field) => mapApiExtraFieldToIExtraField(field));
}

export function mapApiDocumentsToIDocuments(
  apiDocuments: IApiField[],
): IDocument[] {
  return apiDocuments.map((field) => mapApiDocumentToIDocument(field));
}

export function mapApiLanguageToILanguage(apiLanguage: ApiLanguage): ILanguage {
  return {
    id: apiLanguage.id,
    name: apiLanguage.name,
    icon: apiLanguage.icon,
  };
}

export function mapApiLanguagesToILanguages(
  apiLanguages: ApiLanguage[],
): ILanguage[] {
  return apiLanguages.map((lang) => mapApiLanguageToILanguage(lang));
}

export function mapFromFileToFormData(
  file: File,
  width?: number,
  height?: number,
): FormData {
  const formData = new FormData();
  formData.append('file', file);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}
