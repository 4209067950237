import { useAppSelector } from 'state/hooks';

export const useChangableSettings = () => {
  const { moscotUrl: actualMascot, backgroundColor: actualBackgroundColor } = useAppSelector((state) => state.formSettings);
  return { actualMascot, actualBackgroundColor };
};

export const useNodeSettings = () => useAppSelector((state) => state.formSettings.node);

export const useFormSettings = () => useAppSelector((state) => state.formSettings.form);

export const useSettings = () => {
  const {
    sessionId, companyID, googlePlaceId, anonymous, withStep, node,
  } = useAppSelector((state) => state.formSettings);
  return {
    node,
    sessionId,
    companyID,
    googlePlaceId,
    anonymous,
    withStep,
  };
};
