import React, { useEffect } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import Form from './pages/form/Form';
import ErrorPage from './pages/errors/404/ErrorPage';

export const devMode = false;

function App() {
  useEffect(() => {
    if (window.location.hostname.includes('diia.')) {
      // @ts-ignore
      document.querySelector("link[rel='icon']").href = '/favicon.png';
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/reviews/:slug" element={<Form />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/error/:errorCode" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
