import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlugV2 } from 'state/thunk';
import { ILanguage } from 'entities/ILanguage';

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action: PayloadAction<ILanguage>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFormBySlugV2.fulfilled,
      (state, { payload: { nodeForm } }) => {
        const userLang = (
          navigator.language // @ts-ignore
          || navigator.userLanguage
          || 'EN-GB'
        )
          .split('-')[1]
          ?.toLowerCase();
        const langs = nodeForm.form.localizations.map((loc) => loc.language);
        state.languages = langs;
        state.language = langs.find(
          (lang) => lang?.name?.toLowerCase() === userLang,
        );
      },
    );
  },
});

const { actions, reducer } = languageSlice;

export const { setSelectedLanguage } = actions;

export default reducer;
