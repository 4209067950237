import React from 'react';

export function StarIcon({
  width = 36,
  height = 35,
  color = '#F0F0F0',
  borderColor = '#E5E5E5',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 35"
    >
      <defs>
        <filter id="inner-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="0.15 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="2" />
          <feOffset dx="0" dy="3" result="offsetblur" />
          <feFlood floodColor="black" result="color" />
          <feComposite in2="offsetblur" operator="in" />
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
      </defs>
      <g filter="url(#inner-shadow)">
        <path
          fill={color}
          stroke={borderColor}
          strokeWidth="2"
          d="M18 1.388l4.5 9.756a2 2 0 001.58 1.148l10.67 1.265-7.888 7.295a2 2 0 00-.604 1.858l2.094 10.539L18.977 28a2 2 0 00-1.954 0l-9.375 5.248L9.742 22.71a2 2 0 00-.604-1.858L1.25 13.557l10.67-1.265a2 2 0 001.58-1.148L18 1.388z"
        />
      </g>
    </svg>
  );
}
