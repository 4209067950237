import React from 'react';
import { BackButtonStyles } from './BackButtonStyles';
import { IBackButton } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export const BackButton: React.FC<IBackButton> = ({
  handleBackButtonClick,
  children,
  isPageByPageForm,
}) => {
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  return (
    <BackButtonStyles
      fontFamily={fontFamily}
      isFontWeightBold={isFontWeightBold}
    >
      <button type="button" onClick={handleBackButtonClick}>
        {children}
      </button>
    </BackButtonStyles>
  );
};
