import React, { useEffect, useState } from 'react';
import { StarScaleStyles } from './StarScaleStyles';
import { StarIcon } from 'assets/icons';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { ColorScaleProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';
import { IFormStateV2 } from 'pages/form/types';

export function StarScale({
  id,
  selectedStarColor,
  notSelectedStarBorder,
  notSelectedStarBackground,
  maxValue,
  defaultValue,
  signatures,
  errorName,
  value,
  handleChange,
  scaleValues,
  showDefaultValue,
  signatureColor,
}: ColorScaleProps) {
  const { touched, errors } = useFormikContext<IFormStateV2>();

  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const error = errors.extraFields?.[`${id}|||s`]?.answer;
  const [assessment, setAssessment] = useState<number>(0);
  const scaleValueCondition = value || value === 0;

  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue);
      } else {
        setAssessment(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <StarScaleStyles
      signatureColor={signatureColor}
      isFontWeightBold={isFontWeightBold}
    >
      <div className="buttons">
        {scaleValues?.map((number) => {
          const isSelected = scaleValueCondition
            ? value >= number
            : assessment > number;

          return (
            /* eslint-disable-next-line jsx-a11y/control-has-associated-label */
            <button
              key={number}
              type="button"
              onClick={
                handleChange
                  ? () => handleChange(number)
                  : () => setAssessment(number)
              }
            >
              <StarIcon
                width={40}
                height={40}
                color={
                  isSelected ? selectedStarColor : notSelectedStarBackground
                }
                borderColor={
                  isSelected ? selectedStarColor : notSelectedStarBorder
                }
              />
            </button>
          );
        })}
      </div>

      <div className="signatures">
        <span>{signatures[0]}</span>
        <span>{signatures[1]}</span>
      </div>

      <div className="formErrorContainer">
        {touched && (
          <ErrorMessage name={errorName} component={FormErrorMessage} />
        )}
        <p>{error}</p>
      </div>
    </StarScaleStyles>
  );
}
