import { FC } from 'react';
import { TextContainer } from './styles';
import { TCaptionProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export const CaptionField: FC<TCaptionProps> = ({ text, description }) => {
  const { fontFamily, fontUrl, isFontWeightBold } = useFontStyles();
  return (
    <TextContainer fontFamily={fontFamily} isFontWeightBold={isFontWeightBold}>
      <div className="captionWrapper">
        <div className="captionContainer">
          <p>{text}</p>
        </div>
        <div className="descriptionContainer">
          <p>{description}</p>
        </div>
      </div>
    </TextContainer>
  );
};
