import styled from 'styled-components';

export const CheckboxFieldStyles = styled.footer`
  .options {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  .checkboxFieldContainer {
    .choiceDescription {
      margin-top: 12px;

      .answerTitleContainer {
        margin-bottom: 8px;
      }
    }
  }
`;
