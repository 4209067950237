import { TextComponentStyles } from './TextComponentStyles';
import { ITextComponent } from './types';

export function TextComponent({
  font,
  fontSize,
  color,
  children,
}: ITextComponent) {
  return (
    <TextComponentStyles font={font} fontSize={fontSize} color={color}>
      {children}
    </TextComponentStyles>
  );
}
