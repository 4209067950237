import React, { useState } from 'react';
import { RadioFieldStyles } from './RadioFieldStyles';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { SimpleField } from '../simpleField/SimpleField';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { onlySpaces } from 'utils';
import { RadioFieldProps } from './types';
import { RadioButton } from '../radioButton/RadioButton';
import { useFontStyles } from 'hooks/styles/useStyles';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { IFormStateV2 } from 'pages/form/types';
import { DevText } from 'components/dev';

export function RadioField({
  id,
  index,
  values,
  answerPositionV2,
  optionsName,
  optionsError,
  extraBlockStyles,
  disabled,

  question,
  required,

  handleChangeOptionsState,
  handleChangeChoiceDescription,
  description,

  handleSetNextFieldId,
}: RadioFieldProps) {
  const { touched, errors } = useFormikContext<IFormStateV2>();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const { isPageByPageForm } = useFormSettings();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const data = values.extraFields[index];
  const fieldArrayName = `extraFields[${index}].answers`;
  const descriptionIndexAnother = values?.extraFields[
    index
  ]?.answerDescriptionFor?.indexOf(values?.extraFields[index]?.selectedOption);

  function handleKeyUp(key: string) {
    setErrorMessage(
      typeof errorMessage === 'object'
        ? { ...errorMessage, [key]: undefined }
        : errorMessage,
    );
  }

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
          && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
            key
          ].substring(1)}`
      : undefined;
  }
  const error = errors.extraFields?.[`${id}|||s`]?.selectedOption;
  return (
    <RadioFieldStyles style={extraBlockStyles} fontFamily={fontFamily}>
      <div className="titleContainer">
        {/* {required && <span>*</span>} */}
        <h3>
          {questionTextByFormType(
            isPageByPageForm,
            answerPositionV2!,
            question,
          )}
        </h3>
      </div>

      {description && (
        <div className="titleContainer">
          <h3>{description}</h3>
        </div>
      )}

      <FieldArray
        name={fieldArrayName}
        render={() => (
          <div className="options">
            {data?.answers?.map((field: any, subIndex: number) => {
              const descriptionIndex = values.extraFields[
                index
              ].answerDescriptionFor?.indexOf(field.text);
              const value = data.answers[subIndex].text;
              const isAnswersWithCommentRequired = values?.extraFields[index]
                ?.answersWithCommentRequired
                ? values?.extraFields[index]?.answersWithCommentRequired
                : [];
              let leaveCommentAnswers: string;
              if (
                isAnswersWithCommentRequired
                && isAnswersWithCommentRequired.length > 0
              ) {
                leaveCommentAnswers = values?.extraFields[index]?.answersWithCommentRequired[
                  descriptionIndex
                ]?.leaveCommetRequired;
              } else {
                leaveCommentAnswers = 'false';
              }
              // @ts-ignore
              const inputStylesSpecial = leaveCommentAnswers === 'true'
                // @ts-ignore
                && values.extraFields[index].answerDescription[
                  descriptionIndex
                ] === '' // @ts-ignore
                && errors?.extraFields?.[0]?.selectedOption // @ts-ignore
                && touched?.extraFields?.[0]
                ? '#f5cecb'
                : '#F9F9F9';
              const placeholderStylesSpecial = leaveCommentAnswers === 'true'
                // @ts-ignore
                && values.extraFields[index].answerDescription[
                  descriptionIndex
                ] === '' // @ts-ignore
                && errors?.extraFields?.[0]?.selectedOption // @ts-ignore
                && touched?.extraFields?.[0]
                ? '#000000'
                : '';

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="radioFieldContainer" key={subIndex}>
                  <RadioButton
                    index={index}
                    name={`extraFields[${index}].selectedOption`}
                    value={value}
                    onChange={
                      handleChangeOptionsState
                        ? (event) => {
                          if (
                            descriptionIndexAnother !== -1
                              && isAnswersWithCommentRequired.length > 0
                          ) {
                            handleChangeChoiceDescription(
                              `extraFields[${index}].answerDescription[${descriptionIndexAnother}]`,
                              '',
                            );
                          }
                          handleChangeOptionsState(
                            `extraFields[${index}].selectedOption`,
                            event.target.value,
                          );
                          if (isPageByPageForm && handleSetNextFieldId) {
                            handleSetNextFieldId(
                              data.answers[subIndex].nextField
                                  || data.nextFieldId
                                  || undefined,
                            );
                          }
                        }
                        : () => null
                    }
                    checked={
                      values.extraFields[index]?.selectedOption === value
                    }
                  >
                    <div className="radioText">{value}</div>
                  </RadioButton>
                  <DevText>{field.transitTo}</DevText>
                  {values.extraFields[index].selectedOption === value
                    && values.extraFields[index].answerDescriptionFor?.includes(
                      field.text,
                    ) && (
                      <div className="choiceDescription">
                        {!onlySpaces(
                          values.extraFields[index].answerTitle?.[
                            descriptionIndex
                          ],
                        ) && (
                          <div className="answerTitleContainer">
                            <p>
                              {
                                values.extraFields[index].answerTitle?.[
                                  descriptionIndex
                                ]
                              }
                            </p>
                          </div>
                        )}
                        <SimpleField
                          id={id}
                          // isPageByPageForm={isPageByPageForm}
                          index={index}
                          name={`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`}
                          errorName={`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`}
                          onChange={(key, value) => (values.extraFields[index].answerDescription
                            ? handleChangeChoiceDescription(
                              `extraFields[${index}].answerDescription[${descriptionIndex}]`,
                              value,
                            )
                            : null)}
                          onKeyUp={() => handleKeyUp(
                            `extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`,
                          )}
                          value={
                            values.extraFields[index].answerDescription?.[
                              descriptionIndex
                            ]
                          }
                          error={
                            typeof errorMessage === 'object'
                              ? getErrorMessage(
                                `extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`,
                              )
                              : undefined
                          }
                          placeholder={
                            values.extraFields[index].answerPlaceholder?.[
                              descriptionIndex
                            ]
                          }
                          inputType="text"
                          required={false}
                          inputStylesSpecial={inputStylesSpecial}
                          inputPlaceholderSpecial={placeholderStylesSpecial}
                        />
                      </div>
                  )}
                </div>
              );
            })}

            <div className="formErrorContainer">
              {/* {optionsError && <span>{optionsError}</span>}
              {!optionsError && touched && (
                <ErrorMessage name={optionsName} component={FormErrorMessage} />
              )} */}
              <p>{error}</p>
            </div>
          </div>
        )}
      />
    </RadioFieldStyles>
  );
}
