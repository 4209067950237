import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { combineReducers } from 'redux';
import formReducer from './slices/formSlice';
import formReducerV2 from './slices/formSliceV2';
import styleReducerV2 from './slices/stylesSliceV2';
import optionSlice from './slices/optionSlice';
import navigationSliceV2 from './slices/navigationSlice';
import formSettingsSliceV2 from './slices/formSettings';
import languageSlice from './slices/language';

const reducers = combineReducers({
  form: formReducer,
  formV2: formReducerV2,
  formSettings: formSettingsSliceV2,
  styleV2: styleReducerV2,
  navigation: navigationSliceV2,
  option: optionSlice,
  language: languageSlice,
});

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

setupListeners(store.dispatch);
