import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  .dateWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .datePicker {
      width: 100%;
      display: flex;
    }

    .datepicker-toggle {
      display: inline-block;
      position: relative;
      width: 100%;
      max-width: 480px;
      height: 42px;
      background-color: rgb(249, 249, 249);

      border-radius: 3px;
      .iconContainer {
        background-color: rgb(242, 242, 242);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 60px;
        border-radius: 3px;
      }
    }

    .datepicker-toggle-button {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 85%;
      height: 100%;
      align-items: center;
      font-family: Roboto-Light, sans-serif;
      font-size: 14px;
      color: rgb(76, 82, 83);
      padding-left: 67px;
    }
  }

  .datepicker-input {
    position: absolute;
    left: 25%;
    top: 10px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  .datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;
