import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlugV2 } from 'state/thunk';
import { ILanguage } from 'entities/ILanguage';

export const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setPhone: (state, action: PayloadAction<string>) => ({
      ...state,
      phone: action.payload,
    }),
    setSmsVerifyEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      smsVerifyEnabled: action.payload,
    }),
    setToStateSessionId: (state, action: PayloadAction<string>) => ({
      ...state,
      sessionID: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFormBySlugV2.fulfilled,
      (state, { payload: { nodeForm } }) => {},
    );
  },
});

const { actions, reducer } = optionSlice;

export const { setPhone, setSmsVerifyEnabled, setToStateSessionId } = actions;

export default reducer;
