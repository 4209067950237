import styled from 'styled-components';

export const QuantityFieldStyles = styled.footer<{fontFamily: string | undefined, buttonsBackgroundColor: string | undefined}>`
margin-bottom: 30px;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.quantityCounterContainer {
    margin-top: 30px;

    .quantityCounterWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .quantityInput {
            border: none;
            height: 50px;
            width: 50%;
            text-align: center;
            box-sizing: border-box;
            font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto'}, sans-serif`};
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            color: #000000;
            border: 1px solid rgba(0, 0, 0, 0.3);

            &:focus {
                outline: none;
            }
        }

        .quantityCounterButton {
            border: none;
            padding: 0;
            margin: 0;
            width: 24%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ buttonsBackgroundColor }) => buttonsBackgroundColor || '#000'}
        }   

        .quantityMinusButton {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            
        }
        .quantityPlusButton {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .formErrorContainer {
        margin-top: 10px;
        height: 24px;
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
      }
}
`;
