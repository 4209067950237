import React from 'react';

export function CheckMark({ color = '#0E9285' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill={color}
        d="M5.965 10.44L1.967 6.294.25 8.074 5.965 14 17.75 1.78 16.033 0 5.965 10.44z"
      />
    </svg>
  );
}
