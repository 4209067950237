import { LanguageSelector } from '../languageSelector';
import { useState } from 'react';
import { HeaderContainer } from './styles';
import {
  useFormSettings,
  useNodeSettings,
} from 'hooks/formSettings/useFormSettings';
import { useLanguage } from 'hooks/language/useLanguage';
import { useGlobalStyles } from 'hooks/styles/useStyles';

export const Header = () => {
  const { dividingLine } = useGlobalStyles();
  const { name, logo } = useFormSettings();
  const { nodeCode, showNodeCode } = useNodeSettings();
  const { isPageByPageForm } = useFormSettings();
  const { availableLanguages: languages } = useLanguage();

  const showHeaderAdditionalCondition = (languages && languages?.length > 1) || showNodeCode;

  const isHeaderShown = isPageByPageForm || (!isPageByPageForm && showHeaderAdditionalCondition);

  const customizedDividingLineStyles = {
    width: `${dividingLine?.width_in_percent}%`,
    height: `${dividingLine?.height}px`,
    backgroundColor: dividingLine?.color,
  };
  return (
    <HeaderContainer>
      <div className="header">
        <div className="headerInnerContainer">
          {isPageByPageForm && (
            <div className="logoImage">
              <img className="logo" src={logo} alt={name} />
            </div>
          )}
          <LanguageSelector />
        </div>
        {showNodeCode && nodeCode && (
          <div className="nodeNumber">
            <p className="title">{nodeCode}</p>
          </div>
        )}
        {isHeaderShown && dividingLine?.isShown && (
          <div className="dividingLine" style={customizedDividingLineStyles} />
        )}
      </div>
    </HeaderContainer>
  );
};
