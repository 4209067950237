import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlugV2, submitFormV2 } from '../../thunk';

export const navigationSliceV2 = createSlice({
  name: 'formV2',
  initialState,
  reducers: {
    changeStartPage: (state, { payload }: PayloadAction<{ lang: string }>) => {
      state.page = state.startPage[payload.lang];
      state.pagesHistory = [state.startPage[payload.lang]];
    },
    goToPage: (state, action: PayloadAction<{ nextPageId: number }>) => {
      state.page = action.payload.nextPageId;
      state.pagesHistory.push(action.payload.nextPageId);
    },
    goBack: (state) => {
      const historyLength = state.pagesHistory.length;
      if (historyLength > 0) {
        state.page = state.pagesHistory[historyLength - 2];
        state.pagesHistory.pop();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFormBySlugV2.fulfilled,
      (state, { payload: { nodeForm } }) => {
        // @ts-ignore
        const userLang = (
          navigator.language // @ts-ignore
          || navigator.userLanguage
          || 'EN-GB'
        )
          .split('-')[1]
          ?.toLowerCase();

        const startPageId = nodeForm.form.localizations.find(
          (loc) => loc?.language?.name?.toLowerCase() === userLang,
        )?.pages[0].id
          ?? nodeForm.form.localizations.find((loc) => loc.isDefault)?.pages[0]
            .id!;

        const startPage: { [key: string]: number } = {};

        nodeForm.form.localizations.forEach((loc) => {
          startPage[loc?.language?.name] = loc.pages[0].id;
        });

        state.page = startPageId;
        state.pagesHistory = [startPageId];
        state.startPage = startPage;
      },
    );
    // builder.addCase(fetchFormBySlugV2.rejected, (state, action) => {});
    // builder.addCase(submitFormV2.fulfilled, (state, action) => {
    //   state.page = action.payload.nextPageId;
    //   state.pagesHistory.push(action.payload.nextPageId);
    // });
    // builder.addCase(submitFormV2.rejected, (state, action) => {});
  },
});

const { actions, reducer } = navigationSliceV2;
// Extract and export each action creator by name
export const { goToPage, goBack, changeStartPage } = actions;
// Export the reducer, either as a default or named export
export default reducer;
