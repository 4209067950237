import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { goBack, goToPage } from 'state/slices/navigationSlice';

export const useFormNavigation = () => {
  const dispatch = useAppDispatch();
  const { page } = useAppSelector((state) => state.navigation);
  const { localization } = useAppSelector((state) => state.formV2);
  const { language } = useAppSelector((state) => state.language);

  const selectedLanguage = language?.name ?? 'UA';

  const selectedFormLocalization = useMemo(
    () => localization.find(
      (loc) => loc.language.name.toLowerCase() === selectedLanguage.toLowerCase(),
    ) ?? localization.find((loc) => loc.isDefault),
    [localization, selectedLanguage],
  );

  const selectedPage = useMemo(
    () => selectedFormLocalization?.pages?.find((e) => e.id === page),
    [selectedFormLocalization, page, selectedLanguage],
  );

  const navigateToPage = (transitTo?: number) => {
    if (selectedPage) {
      const nextPage = transitTo ?? selectedPage?.nextPageID!;
      dispatch(
        goToPage({
          nextPageId: nextPage,
        }),
      );
    }
  };

  const goToPreviousPage = useCallback(() => {
    dispatch(goBack());
  }, []);

  return {
    page,
    navigateToPage,
    goToPreviousPage,
  };
};
