import React, { FC } from 'react';
import { HiddenFieldStyles } from './HiddenFieldStyles';
import { THiddenField } from './types';

export const HiddenField: FC<THiddenField> = React.memo(
  ({
    index, name, value, onChange,
  }) => (
    <HiddenFieldStyles>
      <input
        name={name}
        type="hidden"
        value={value}
        onChange={(event) => onChange(name, event.target.value)}
      />
    </HiddenFieldStyles>
  ),
);
