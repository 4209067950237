export const translations: { [key: string]: { [key: string]: string } } = {
  EN: {
    google_modal: 'Want to leave a review on Google Maps?',
    yes: 'Yes',
    submit: 'Submit',
    next: 'Next',
    back: 'Back',
    start: 'Start',
    skip: 'Skip',
    name: 'Name',
    upload_audio: 'Record audio',
    upload_document: 'Upload file',
    upload_image: 'Upload image',
    upload_video: 'Upload video',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    viber: 'Viber',
    call: 'Call',
    email: 'Email',
    enter_telephone_number: 'Please, enter your phone',
    enter_your_name: 'Please, enter your name',
    without_answer: 'Without answer',
    validation_required: 'Required',
    validation_at_least_1_file: 'You must upload at least 1 file',
    validation_at_least_1_option: 'You must select at least 1 option',
    validation_fill_comment: 'Marked fields are mandatory',
    validation_phone_number_length: 'Mobile number is invalid',
    validation_mobile_code_operator:
      'The code of the mobile operator is incorrect',
    validation_choose_mark: 'Choose your mark',
    validation_choose_one_network: 'Choose one communication channels',
    validation_agree_the_terms: 'Agree to the terms',
    telegram_bot_usage_text_1: 'You will be redirected to the telegram bot.',
    viber_bot_usage_text_1: 'You will be redirected to the viber bot.',
    bot_usage_text: 'To receive a response from us, click ',
    start_bot_text: '"Start"',
    validate_quantity_number: 'In this field could be entered numbers from ',
    till_text: 'to',
    validation_choose_one_from_several_options: 'Choose one of the options',
    upload_files_not_downloaded_error: 'File upload error',
    max_upload_files_amount:
      'You have uploaded the maximum number of files possible',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    sms: 'SMS',
    validation_email: 'Invalid email',
    selectDate: 'Select date',
  },

  SR: {
    next: 'Sledeći',
    back: 'Nazad',
    validation_required: 'Обавезно поље',
    validation_at_least_1_file: 'Морате да отпремите најмање 1 датотеку',
    validation_at_least_1_option: 'Морате да изаберете најмање 1 опцију',
    validation_fill_comment: 'Označena polja su obavezna',
    validation_phone_number_length: 'Broj mobitela je nevažeći',
    validation_mobile_code_operator: 'Kod mobilnog operatera je netačan',
    validation_agree_the_terms: 'Obavezno polje',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Unesite svoje ime',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  DE: {
    next: 'Nächste',
    back: 'Zurück',
    validation_required: 'Pflichtfeld',
    validation_at_least_1_file: 'Sie müssen mindestens 1 Datei hochladen',
    validation_at_least_1_option: 'Sie müssen mindestens 1 Option auswählen',
    validation_fill_comment: 'Markierte Felder sind Pflichtfelder',
    validation_phone_number_length: 'Handynummer ist ungültig',
    validation_mobile_code_operator:
      'Der Code des Mobilfunkanbieters ist falsch',
    validation_agree_the_terms: 'Pflichtfeld',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Geben Sie Ihren Namen ein',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  HR: {
    next: 'Sljedeći',
    back: 'Nazad',
    validation_required: 'Obvezno',
    validation_at_least_1_file: 'Naložiti morate vsaj 1 datoteko',
    validation_at_least_1_option: 'Izbrati morate vsaj 1 možnost',
    validation_fill_comment: 'Označena polja su obavezna',
    validation_phone_number_length: 'Broj mobitela je nevažeći',
    validation_mobile_code_operator: 'Šifra mobilnog operatera je netočna',
    validation_agree_the_terms: 'Obvezno',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Unesite svoje ime',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  UA: {
    google_modal: 'Бажаєте залишити відгук на картах Google?',
    yes: 'Так',
    next: 'Далі',
    back: 'Назад',
    start: 'Почати',
    skip: 'Пропустити',
    name: "Ім'я",
    upload_audio: 'Аудіо',
    upload_document: 'Документ',
    upload_image: 'Фото',
    upload_video: 'Відео',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    viber: 'Viber',
    call: 'Дзвінок',
    email: 'Email',
    enter_your_name: 'Вкажіть ваше ім’я',
    enter_telephone_number: 'Вкажіть номер телефону',
    without_answer: 'Ні, дякую',
    validation_required: "Обов'язкове поле",
    validation_at_least_1_file: 'Ви повинні завантажити принаймні 1 файл',
    validation_at_least_1_option: 'Оберіть принаймні 1 варіант',
    validation_phone_number_length: 'Номер мобільного телефону невірний',
    validation_mobile_code_operator: 'Невірно вказано код мобільного оператора',
    validation_fill_comment: "Виділені поля обов'язкові для заповнення",
    validation_choose_mark: 'Оберіть Вашу оцінку',
    validation_choose_one_network: 'Оберіть один з каналів зв’язку',
    validation_agree_the_terms: 'Погодьтесь з умовами, будь ласка',
    telegram_bot_usage_text_1: 'Вас буде перенаправлено в telegram-бот.',
    viber_bot_usage_text_1: 'Вас буде перенаправлено в viber-бот.',
    telegram_bot_usage_text_2:
      'Після заповнення форми, вас буде перенаправлено у telegram-бот.',
    viber_bot_usage_text_2:
      'Після заповнення форми, вас буде перенаправлено у viber-бот.',
    bot_usage_text:
      'Для того, щоб ми змогли Вам надіслати відповідь, натисніть ',
    bot_usage_text2:
      'Вам необхідно натиснути «Почати/Start», щоб отримати зворотній зв‘язок від Ощадбанку.',
    start_bot_text: '"Почати/Start"',
    validate_quantity_number: 'В дане поле можна ввести лише числа від ',
    till_text: 'до',
    validation_choose_one_from_several_options: 'Оберіть один з варіантів',
    upload_files_not_downloaded_error: 'Помилка завантаження файлів',
    submit: 'Відправити',
    max_upload_files_amount:
      'Ви завантажили максимально можливу кількість файлів',
    enter_email: 'Вкажіть електронну адресу',
    refresh: 'Оновити',
    sms: 'SMS',
    validation_email: 'Електронна пошта невірна',
    selectDate: 'Оберіть дату',
  },

  BiH: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Sledeći',
    back: 'Nazad',
    validation_required: 'Обавезно поље',
    validation_at_least_1_file: 'Морате да отпремите најмање 1 датотеку',
    validation_at_least_1_option: 'Морате да изаберете најмање 1 опцију',
    validation_fill_comment: 'Označena polja su obavezna',
    validation_phone_number_length: 'Broj mobilnog telefona je nevažeći',
    validation_mobile_code_operator: 'Kod mobilnog operatera je netačan',
    validation_agree_the_terms: 'Obavezno polje',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Unesite svoje ime',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  ME: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Sljedeći',
    back: 'Nazad',
    validation_required: 'Obvezno',
    validation_at_least_1_file: 'Naložiti morate vsaj 1 datoteko',
    validation_at_least_1_option: 'Izbrati morate vsaj 1 možnost',
    validation_fill_comment: 'Označena polja su obavezna',
    validation_phone_number_length: 'Broj mobitela je nevažeći',
    validation_mobile_code_operator: 'Šifra mobilnog operatera je netočna',
    validation_agree_the_terms: 'Obavezno',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Unesite svoje ime',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  SK: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Ďalšie',
    back: 'Späť',
    validation_required: 'Povinné pole',
    validation_at_least_1_file: 'Musíte nahrať aspoň 1 súbor',
    validation_at_least_1_option: 'Musíte vybrať aspoň 1 možnosť',
    validation_fill_comment: 'Označené polia sú povinné',
    validation_phone_number_length: 'Mobilné číslo je neplatné',
    validation_mobile_code_operator: 'Kód mobilného operátora je nesprávny',
    validation_agree_the_terms: 'Povinné pole',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Prosím, zadajte vaše meno',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  CZ: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Nächste',
    back: 'Zadní',
    validation_required: 'Povinné pole',
    validation_at_least_1_file: 'Musíte nahrát alespoň 1 soubor',
    validation_at_least_1_option: 'Musíte vybrat alespoň 1 možnost',
    validation_fill_comment: 'Označená pole jsou povinná',
    validation_phone_number_length: 'Mobilní číslo je neplatné',
    validation_mobile_code_operator: 'Kód mobilního operátora je nesprávný',
    validation_agree_the_terms: 'Povinné pole',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Zadejte prosím své jméno',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  HU: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Következő',
    back: 'Vissza',
    validation_required: 'Kötelezően kitöltendő mező',
    validation_at_least_1_file: 'Fel kell töltened legalább 1 fájlt',
    validation_at_least_1_option: 'Legalább 1 lehetőséget ki kell választania',
    validation_fill_comment: 'A megjelölt mezők kitöltése kötelező',
    validation_phone_number_length: 'A mobilszám érvénytelen',
    validation_mobile_code_operator: 'A mobilszolgáltató kódja hibás',
    validation_agree_the_terms: 'Kötelezően kitöltendő mező',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Molimo unesite svoje ime',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  RO: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Următorul',
    back: 'Înapoi',
    validation_required: 'Câmp Obligatoriu',
    validation_at_least_1_file: 'Trebuie să încărcați cel puțin 1 fișier',
    validation_at_least_1_option: 'Trebuie să selectați cel puțin o opțiune',
    validation_fill_comment: 'Câmpurile marcate sunt obligatorii',
    validation_phone_number_length: 'Numărul de telefon mobil este nevalid',
    validation_mobile_code_operator:
      'Codul operatorului de telefonie mobilă este incorect',
    validation_agree_the_terms: 'Câmp Obligatoriu',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Te rog scrieti numele',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  SL: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Naslednji',
    back: 'Nazaj',
    validation_required: 'Obvezno polje',
    validation_at_least_1_file: 'Naložiti morate vsaj 1 datoteko',
    validation_at_least_1_option: 'Izbrati morate vsaj 1 možnost',
    validation_fill_comment: 'Označena polja so obvezna',
    validation_phone_number_length: 'Številka mobilnega telefona je neveljavna',
    validation_mobile_code_operator: 'Koda mobilnega operaterja ni pravilna',
    validation_agree_the_terms: 'Obvezno polje',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Zadajte svoje meno',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  MT: {
    google_modal: 'Want to leave a review on Google Maps?',
    submit: 'Potvrdi',
  },

  KZ: {
    google_modal: 'Want to leave a review on Google Maps?',
    next: 'Next',
    back: 'Back',
    validation_required: 'Required',
    validation_at_least_1_file: 'You must upload at least 1 file',
    validation_at_least_1_option: 'You must select at least 1 option',
    validation_fill_comment: 'Marked fields are mandatory',
    validation_phone_number_length: 'Mobile number is invalid',
    validation_mobile_code_operator:
      'The code of the mobile operator is incorrect',
    validation_choose_mark: 'Choose your mark',
    validation_choose_one_network: 'Choose one communication channels',
    validation_agree_the_terms: 'Agree to the terms',
    enter_email: 'Please, enter your email',
    refresh: 'Refresh',
    enter_your_name: 'Please, enter your name',
    sms: 'SMS',
    validation_email: 'Invalid email',
  },

  RU: {
    google_modal: 'Хотите оставить отзыв на Google Maps?',
    yes: 'Да',
    submit: 'Отправить',
    next: 'Следующий',
    back: 'Назад',
    start: 'Начать',
    skip: 'Пропустить',
    name: 'Имя',
    upload_audio: 'Записать аудио',
    upload_document: 'Загрузить файл',
    upload_image: 'Загрузить изображение',
    upload_video: 'Загрузить видео',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    viber: 'Viber',
    call: 'Звонок',
    email: 'Электронная почта',
    enter_telephone_number: 'Пожалуйста, введите ваш телефон',
    enter_your_name: 'Пожалуйста, введите ваше имя',
    without_answer: 'Без ответа',
    validation_required: 'Требуется',
    validation_at_least_1_file: 'Вы должны загрузить хотя бы 1 файл',
    validation_at_least_1_option: 'Вы должны выбрать хотя бы 1 вариант',
    validation_fill_comment: 'Отмеченные поля обязательны для заполнения',
    validation_phone_number_length: 'Номер мобильного телефона недействителен',
    validation_mobile_code_operator: 'Код мобильного оператора неверен',
    validation_choose_mark: 'Выберите свою метку',
    validation_choose_one_network: 'Выберите один канал связи',
    validation_agree_the_terms: 'Согласиться с условиями',
    telegram_bot_usage_text_1: 'Вы будете перенаправлены на telegram-бота',
    viber_bot_usage_text_1: 'Вы будете перенаправлены на viber-бота',
    bot_usage_text: 'Чтобы получить от нас ответ, нажмите ',
    start_bot_text: 'Начать',
    validate_quantity_number: 'В это поле можно вводить числа от ',
    till_text: 'до',
    validation_choose_one_from_several_options: 'Выберите один из вариантов',
    upload_files_not_downloaded_error: 'Ошибка загрузки файла',
    max_upload_files_amount:
      'Вы загрузили максимально возможное количество файлов',
    enter_email: 'Пожалуйста, введите свой email',
    refresh: 'Обновить',
    sms: 'SMS',
    validation_email: 'Неверный email',
  },

  UZ: {
    google_modal: 'Google Xaritalarda sharh qoldirmoqchimisiz?',
    yes: 'Ha',
    Submit: 'Yuborish',
    next: 'Keyingi',
    back: 'Orqaga',
    start: 'Boshlash',
    skip: "O'tkazib yuborish",
    name: 'Ism',
    upload_audio: 'Ovozni yozib olish',
    upload_document: 'Faylni yuklash',
    upload_image: 'Rasmni yuklash',
    upload_video: 'Video yuklash',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    viber: 'Viber',
    call: "Qo'ng'iroq",
    email: 'Elektron pochta',
    enter_telephone_number: 'Iltimos, telefon raqamingizni kiriting',
    enter_your_name: 'Iltimos, ismingizni kiriting',
    without_answer: "Javob yo'q",
    validation_required: 'Majburiy',
    validation_at_least_1_file: 'Siz kamida 1 ta faylni yuklashingiz kerak',
    validation_at_least_1_option:
      'Siz kamida 1 ta variantni tanlashingiz kerak',
    validation_fill_comment:
      "Belgilangan maydonlar to'ldirish uchun majburiydir",
    validation_phone_number_length: 'Mobil telefon raqami haqiqiy emas',
    validation_mobile_code_operator: "Mobil operator kodi noto'g'ri",
    validation_choose_mark: 'Belgingizni tanlang',
    validation_choose_one_network: 'Bitta aloqa kanalini tanlang',
    validation_agree_the_terms: 'Shartlarga rozilik bildiraman',
    telegram_bot_usage_text_1: "Siz telegram botiga yo'naltirilasiz",
    viber_bot_usage_text_1: "Siz viber botga yo'naltirilasiz",
    bot_usage_text: "Bizdan javob olish uchun ' tugmasini bosing",
    start_bot_text: 'Boshlash',
    validate_quantity_number: 'Ushbu maydon raqamlarni qabul qiladi',
    till_text: 'oldin',
    validation_choose_one_from_several_options: 'Variantlardan birini tanlang',
    upload_files_not_downloaded_error: 'Faylni yuklashda xatolik',
    max_upload_files_amount: 'Siz maksimal fayl sonini yukladingiz',
    enter_email: 'Iltimos, elektron pochta manzilingizni kiriting',
    refresh: 'yangilash',
    sms: 'SMS',
    validation_email: "Noto'g'ri elektron pochta",
  },
};
