import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckboxAnswer } from '../../../api/mappers';
import { initialState } from './initialState';

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    // Reducer comes here
    resetToInitialState: () => initialState,
    setElementsColor: (state, action: PayloadAction<string>) => ({
      ...state,
      elementsColor: action.payload,
    }),
    addField: (state, action: PayloadAction<any[]>) => ({
      ...state,
      fields: [...state.fields, ...action.payload],
    }),
    deleteField: (state, action: PayloadAction<{ id: number }>) => ({
      ...state,
      fields: state.fields.filter((item) => item.id !== action.payload.id),
    }),
    setAnswer: (
      state,
      action: PayloadAction<{
        answer: string | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? { ...field, answer: action.payload.answer }
        : field)),
    }),
    setSelectedRadioButton: (
      state,
      action: PayloadAction<{
        selectedOption: string | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? { ...field, selectedOption: action.payload.selectedOption }
        : field)),
    }),
    setAnswerDescription: (
      state,
      action: PayloadAction<{
        answerDescription: string[] | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? { ...field, answerDescription: action.payload.answerDescription }
        : field)),
    }),
    setDocumentTermsAgreed: (
      state,
      action: PayloadAction<{ selected: boolean; id: number }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id && field.type === 'document'
        ? { ...field, selected: action.payload.selected }
        : field)),
    }),
    setSelectedCheckboxes: (
      state,
      action: PayloadAction<{
        answers: ICheckboxAnswer[] | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? { ...field, answers: action.payload.answers }
        : field)),
    }),
    setFormFontData: (
      state,
      action: PayloadAction<{
        fontFamily: string | undefined;
        fontUrl: string | undefined;
      }>,
    ) => ({ ...state, formFontData: action.payload }),
    setFormLanguage: (state, action: PayloadAction<string>) => ({
      ...state,
      language: action.payload,
    }),
    updateFormStyles: (state, action) => {
      state.formStyles = { ...state.formStyles, ...action.payload };
    },
    setAdditionalFieldAnswer: (
      state,
      action: PayloadAction<{
        additionalFieldAnswer: string | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? {
          ...field,
          additionalFieldAnswer: action.payload.additionalFieldAnswer,
        }
        : field)),
    }),
    setNotCheckedElementsColor: (state, action: PayloadAction<string>) => ({
      ...state,
      notCheckedElementsColor: action.payload,
    }),
    setFormRadioButtonStyles: (
      state,
      action: PayloadAction<{
        radioBtnBorderWidth: string;
        checkedRadioBtnInnerRoundSizes: string;
      }>,
    ) => ({ ...state, formRadioButtonStyles: action.payload }),
    setUploadFileAnswer: (
      state,
      action: PayloadAction<{
        answer: { key: string; value: string }[] | undefined;
        id: number | undefined;
      }>,
    ) => ({
      ...state,
      fields: state.fields.map((field) => (field.id === action.payload.id
        ? { ...field, answer: action.payload.answer }
        : field)),
    }),
    updateFormSettings: (state, action) => {
      state.formSettings = { ...state.formSettings, ...action.payload };
    },
  },
  extraReducers: {},
});

const { actions, reducer } = formSlice;
// Extract and export each action creator by name
export const {
  setElementsColor,
  addField,
  deleteField,
  setAnswer,
  setSelectedRadioButton,
  setAnswerDescription,
  setDocumentTermsAgreed,
  setSelectedCheckboxes,
  setFormFontData,
  setFormLanguage,
  updateFormStyles,
  setAdditionalFieldAnswer,
  setNotCheckedElementsColor,
  setFormRadioButtonStyles,
  setUploadFileAnswer,
  resetToInitialState,
  updateFormSettings,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
