import React from 'react';
import { Oval } from 'react-loader-spinner';
import { LoaderStyles } from './styles';

export function Loader({
  height = 40,
  color = '#0E9285',
  margin = 88,
}: {
  height?: number;
  color?: string;
  margin?: number;
}) {
  return (
    <LoaderStyles margin={margin}>
      <Oval
        color={color}
        height={height}
        width={height}
        strokeWidth={height / 5}
        strokeWidthSecondary={height / 5}
        secondaryColor={`${color}99`}
      />
    </LoaderStyles>
  );
}
