import { FileUploadPermissionModuleStyles } from './FileUploadPermissionModuleStyles';
import React, { useRef, useState } from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { translations } from 'i18n/translations';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { FileProps, FileUploadPermissionModuleProps } from './types';
import { FormErrorMessage } from 'components/formErrorMessage';
import {
  MicrophoneIcon,
  JustPlusIcon,
  DocumentIcon,
  ImageIcon,
  VideoIcon,
} from 'assets/icons';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { useLanguage } from 'hooks/language/useLanguage';
import { IFormStateV2 } from 'pages/form/types';

const maxSize = 100_000_000;

type FileType =
  | 'image/png,image/png'
  | 'audio'
  | 'document'
  | 'image'
  | 'video';

export function FileUploadPermissionModule({
  id,
  name,
  index,
  values,
  // error,
  setFieldValue,
  required,
  question,
  extraBlockStyles,
  description,
  answerPositionV2,
}: FileUploadPermissionModuleProps) {
  const { touched, errors } = useFormikContext<IFormStateV2>();
  // TODO WARNING!!!!
  const language = useLanguage().language?.name ?? 'UA';
  const { isPageByPageForm } = useFormSettings();
  const [maxSizeError, setMaxSizeError] = useState<string>('');
  const [fileTypeError, setFileTypeError] = useState<string>('');
  const uploadFilesOptionsDirection = values?.uploadFilesFieldOptionsPosition;
  const multipleFileInput = values?.multipleFileInput;
  const isDisabled = isPageByPageForm && values?.answer?.length === 5;
  const fileIconColor = isPageByPageForm && values?.answer?.length === 5 ? '#d4d6d5' : '#636363';

  const selectedType = useRef<string>(null);

  const fileInput = useRef<any>(null);
  const imageInput = useRef<any>(null);
  const audioInput = useRef<any>(null);
  const videoInput = useRef<any>(null);

  const refs = {
    audio: audioInput,
    document: fileInput,
    image: imageInput,
    video: videoInput,
    'image/png,image/png': imageInput,
  };

  const names = {
    audio: translations[language].upload_audio,
    document: translations[language].upload_document,
    image: translations[language].upload_image,
    video: translations[language].upload_video,
    'image/png,image/png': translations[language].upload_image,
  };

  const handleClick = (type: FileType) => {
    // @ts-ignore
    selectedType.current = type;
    const ref = refs[type];

    if (ref?.current) {
      // @ts-ignore
      ref?.current.click();
    }
  };

  function getFileTypeImage(type: FileType) {
    const types = {
      audio: <MicrophoneIcon color={fileIconColor} />,
      document: <DocumentIcon color={fileIconColor} />,
      image: <ImageIcon color={fileIconColor} />,
      'image/png,image/png': <ImageIcon color={fileIconColor} />,
      video: <VideoIcon color={fileIconColor} />,
    };

    return types[type];
  }

  function getFileTypeFormats(
    type: 'audio' | 'document' | 'image' | 'video',
  ): string {
    const types = {
      audio: '.mp3, .wav, .aac, .m4a',
      document: '.pdf, .dock, .xlsx, .csv, .txt',
      image: '.png, .jpg, .heic, .jpeg',
      video: '.mp4, .mov',
    };

    return types[type];
  }

  const listStyles = `options ${uploadFilesOptionsDirection || 'row'}`;

  const error = errors.extraFields?.[`${id}|||s`]?.answer;

  return (
    <FileUploadPermissionModuleStyles style={extraBlockStyles}>
      {question && (
        <div className="titleContainer">
          <h3>
            {questionTextByFormType(
              isPageByPageForm,
              answerPositionV2,
              question,
            )}
          </h3>
        </div>
      )}

      {description && (
        <div className="titleContainer">
          <h3>{description}</h3>
        </div>
      )}

      <FieldArray
        name={`extraFields[${index}].answer`}
        render={({ remove, push }) => (
          <div className="fieldBody">
            <div className={listStyles}>
              {values?.options?.map((field: any, subIndex: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="checkboxFieldContainer" key={subIndex}>
                  <input
                    name={`${name}[${field.value}]`}
                    autoComplete="false"
                    className="defaultFilePicker"
                    ref={refs[field.value as FileType]}
                    type="file"
                    multiple={multipleFileInput}
                    accept={getFileTypeFormats(field.value)}
                    onChange={(event) => {
                      if (event.currentTarget.files?.length) {
                        const files = Array.from(event.currentTarget.files);

                        files.forEach((el) => {
                          const allowedFormats: string[] = getFileTypeFormats(
                            field.value,
                          ).split(', ');

                          const fileExtension: string | undefined = el?.name // eslint-disable-next-line no-bitwise
                            .slice(el?.name.lastIndexOf('.') >>> 0)
                            .toLowerCase();

                          if (
                            fileExtension
                            && !allowedFormats.includes(fileExtension)
                          ) {
                            setFileTypeError(
                              `* Unsupported format ${fileExtension}`,
                            );
                          }

                          if (el?.size && el?.size > maxSize) {
                            setMaxSizeError(
                              '* Maximum allowed file size exceeded',
                            );
                          }

                          if (el) {
                            push({ key: selectedType?.current, value: el });
                          }
                          setMaxSizeError('');
                          setFileTypeError('');
                        });
                      } else {
                        setFileTypeError('Something wrong');
                      }
                    }}
                  />
                  <button
                    type="button"
                    disabled={isDisabled}
                    onClick={() => handleClick(field.value)}
                    className={
                      field.answer?.value !== undefined ? 'selected' : ''
                    }
                  >
                    <div className="iconContainer">
                      {getFileTypeImage(field.value)}
                    </div>
                  </button>
                  <span
                    className={
                      isDisabled ? 'actionType disabled' : 'actionType'
                    }
                  >
                    {names[field.value as FileType]}
                  </span>
                </div>
              ))}
            </div>
            <div className="formErrorContainer">
              {maxSizeError && (
                <span className="uploadError">{maxSizeError}</span>
              )}
              {fileTypeError && (
                <span className="uploadError">{fileTypeError}</span>
              )}
              {error && <span>{error}</span>}
              {!error && touched && (
                <ErrorMessage name={name} component={FormErrorMessage} />
              )}
            </div>

            <div
              className="answers"
              style={
                values?.answer?.filter(
                  (item: FileProps | undefined) => item?.value,
                ).length > 0
                  ? { marginBottom: 42 }
                  : {}
              }
            >
              {values?.answer?.map(
                (item: FileProps | undefined, index: number) => {
                  if (item?.value) {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="fileItem" key={index}>
                        <span>{names[item.key as FileType]}</span>
                        <p>{item.value.name}</p>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button type="button" onClick={() => remove(index)}>
                          <JustPlusIcon />
                        </button>
                      </div>
                    );
                  }

                  return null;
                },
              )}
            </div>
            {isDisabled && (
              <div className="uploadFilesLimitTitle">
                <p>{translations[language].max_upload_files_amount}</p>
              </div>
            )}
          </div>
        )}
      />
    </FileUploadPermissionModuleStyles>
  );
}
