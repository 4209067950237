import { IBaseApi, TRequestOptions } from './IBaseApi';

export default class BaseApi implements IBaseApi {
  readonly baseUrl: string = process.env.REACT_APP_API_HOST!;

  public async fetchData<T>(
    path: string,
    requestOptions: any,
  ): Promise<{ data: T; statusCode: number }> {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;
      const data = await response.json();
      return {
        data,
        statusCode,
      };
    } catch (e) {
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  public async getData<T>(
    path: string,
    customHeaders?: { [key: string]: string },
  ): Promise<{ data: T; statusCode: number }> {
    let myHeaders: { [key: string]: string } = {};

    myHeaders = { ...customHeaders };

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  public async postData<T>(
    path: string,
    data?: any,
    tokenRequired = true,
  ): Promise<{ data: T; statusCode: number }> {
    const myHeaders: { [key: string]: string } = {};
    myHeaders['Content-Type'] = 'application/json';

    const requestOptions: TRequestOptions<string> = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  public async postFormData<T>(
    path: string,
    data: { [key: string]: string } | FormData,
  ): Promise<{ data: T; statusCode: number }> {
    const myHeaders = new Headers();
    let formData: FormData | undefined;

    if (!(data instanceof FormData)) {
      formData = new FormData();
      Object.entries(data).forEach((item) => {
        formData = new FormData();
        formData.append(item[0], item[1]);
      });
    }

    const requestOptions: TRequestOptions<FormData> = {
      method: 'POST',
      headers: myHeaders,
      body: formData || (data as FormData),
      redirect: 'follow',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }
}
