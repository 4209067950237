import ApiResponse from '../api/entities/ApiResponse';
import { IDocument, IExtraField } from '../entities/IExtraField';

export function isFieldNumberExist(fieldId: number | null) {
  if (fieldId && fieldId > 0) {
    return true;
  }
  return false;
}

export function getActualFileData() {
  let fileIndex = -1;

  return (item: {key: string, value: File, fileUrl?: string}, allFilesData: ApiResponse<{ url: string }>[]) => {
    fileIndex += 1;

    if (item.value) {
      return {
        ...item,
        fileUrl: allFilesData[fileIndex]?.data.url,
      };
    }

    return item;
  };
}

export function handleIsRequiredField(field: IExtraField | IDocument | undefined) {
  return !!field?.required;
}

export const handleDidScaleValueChanged = (() => {
  const lastArgs = new Set();
  return (field: IExtraField | undefined) => {
    if (field?.type.startsWith('scale')) {
      if (field?.answer) {
        const lengthValue = 1;
        lastArgs.add(field?.answer);
        const lastArgsArr = Array.from(lastArgs);
        if (lastArgsArr.length <= lengthValue) {
          return false;
        }
        return true;
      }
    }
  };
})();

export function onlySpaces(str: string) {
  return str.trim().length === 0;
}

export const getFileFromPrevAnswerThatIsNotExistInCurrentAnswer = (arr1: any, arr2: any) => arr1
  .filter((o1: any) => !arr2.map((o2: any) => o2.value.name).includes(o1.value.name) || !o1.hasOwnProperty('fileUrl'));

export function scaleFieldExist(data: any) {
  const scaleField = data?.find((item: any) => item.type?.includes('scale'));
  if (scaleField) {
    return true;
  }
  return false;
}

export async function getUserLocationData() {
  return fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      console.log('err', err);
    });
}
