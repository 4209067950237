import UA from '../assets/flags/ua.png';
import BiH from '../assets/flags/bih.png';
import CZ from '../assets/flags/cz.png';
import DE from '../assets/flags/de.png';
import GB from '../assets/flags/gb.png';
import HR from '../assets/flags/hr.png';
import KZ from '../assets/flags/kz.png';
import RO from '../assets/flags/ro.png';
import SK from '../assets/flags/sk.png';
import SR from '../assets/flags/sr.png';
import UZ from '../assets/flags/uz.png';

export const getFlagUrl = (lang: string) => {
  switch (lang) {
    case 'ua':
      return UA;
    case 'bih':
      return BiH;
    case 'cz':
      return CZ;
    case 'de':
      return DE;
    case 'en':
      return GB;
    case 'hr':
      return HR;
    case 'kz':
      return KZ;
    case 'ro':
      return RO;
    case 'sk':
      return SK;
    case 'sr':
      return SR;
    case 'uz':
      return UZ;
    default:
      return '';
  }
};
