import { FC } from 'react';
import { ParagraphContainer } from './styles';
import { TParagraphProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export const ParagraphField: FC<TParagraphProps> = ({ text }) => {
  const { fontFamily, fontUrl, isFontWeightBold } = useFontStyles();
  return (
    <ParagraphContainer
      fontFamily={fontFamily}
      isFontWeightBold={isFontWeightBold}
    >
      <div className="pictureWrapper">
        <div className="pictureContainer">
          <p>{text}</p>
        </div>
      </div>
    </ParagraphContainer>
  );
};
