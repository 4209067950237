import styled from 'styled-components';

export const ModalGoogleStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(34,83,85,.2);
  display: grid;
  place-items: center;
  box-sizing: border-box;

  .modalBody {
    font-family: "Roboto";

    @media screen and (max-width: 460px) {
      margin: 0 30px;
    }

    .alert-card {
        background: #fffaee;
        border: 1px solid #ffba0a;
        border-radius: 0.5rem;
        padding: 1rem 4rem 1rem;
        font-size: 15px;
        line-height: 130%;
        color: #000;
        position: relative;

        &__title {
            font-weight: 600;
            text-align: center;
        }

        &__close {
            cursor: pointer;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 18px;
            height: 20px;
            width: 20px;
            transition: all .3s;
            top: 0;
            right: 0;
            transform: translate(50%,-50%);
        }

        &__btn {
            width: 65px;
            height: 32px;
            background: #ffba0a;
            border-radius: 8px;
            margin: 10px auto 0;
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }
    }
  }
`;
