import React from 'react';

export function ErrorIcon({ color = '#0E9285' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
      <path
        fill={color}
        d="M64 352c17.69 0 32-14.32 32-31.1V64.01C96 46.34 81.69 32 64 32S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zm0 48c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1 40-17.9 40-39.1-17.91-40-40-40z"
      />
    </svg>
  );
}
