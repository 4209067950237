import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlugV2 } from '../../thunk';
import { globalStylesAdapter } from 'adapters';
import { fieldStylesAdapter } from 'adapters/styles/fieldStylesAdapter';
import { formStylesAdapter } from 'adapters/styles/formStylesAdapter';

export const stylesSliceV2 = createSlice({
  name: 'stylesV2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFormBySlugV2.fulfilled, (state, action) => {
      const newStyles = globalStylesAdapter(
        action.payload.nodeForm.form.styles,
      );

      const fieldStyles = fieldStylesAdapter(
        action.payload.nodeForm.form.localizations[0].pages,
      );

      const formStyles = formStylesAdapter(action.payload);

      state.formStyles = formStyles;
      state.fieldStyles = fieldStyles;
      state.fontStyles = newStyles.fontStyles;
      state.globalStyles = newStyles.globalStyles;
      state.submitButtonStyles = newStyles.submitButtonStyles;
    });
  },
});

const { actions, reducer } = stylesSliceV2;
// Extract and export each action creator by name
// export const {} = actions;
// Export the reducer, either as a default or named export
export default reducer;
