import { IApiStyleV2 } from 'api/entities_v2/ApiFormV2';
import {
  IFontStylesV2,
  IGlobalStylesV2,
  ISubmitButtonStylesV2,
  StylesStateV2,
} from 'state/slices/stylesSliceV2/types';

// const defaultField = {}

function toMap(array: { key: string; value: string }[]): Map<string, string> {
  const map = new Map();
  array.forEach((e) => {
    map.set(e.key, e.value);
  });
  return map;
}

export function globalStylesAdapter(apiStyles: IApiStyleV2[]): StylesStateV2 {
  const apiStyleMap = toMap(apiStyles);

  const globalStyles: IGlobalStylesV2 = {
    dividingLine: {
      color:
        apiStyleMap.get('dividing_decorative_line_color')?.split('|||')[1]
        ?? 'transparent',
      isShown: !!apiStyleMap.get('show_dividing_decorative_line'),
      width_in_percent: +(
        apiStyleMap.get('dividing_decorative_line_width_in_percent') ?? 0
      ),
      height: +(apiStyleMap.get('dividing_decorative_line_height') ?? 0),
    },
    // {"" => "Division color|||#000"}, {"" => "true"}, {"" => "90"}, {"" => "2"}},
    backgroundColor: apiStyleMap.get('background_color')?.split('|||'),
    logoLocation: apiStyleMap.get('logoLocation'),
    logoSelected: apiStyleMap.get('logo_selected')?.split('|||')[1],

    startPageButtonPercentWidth: apiStyleMap.get(
      'start_page_button_percent_width',
    ),
    isTransparentBg: apiStyleMap.get('transparent_bg') === 'true',
    notCheckedElementsColor: apiStyleMap
      .get('not_checked_elements_color')
      ?.split('|||'),
    elementsColor: apiStyleMap.get('elements_color'),
    radioButtonBorderWidth: apiStyleMap.get('radio_button_border_width'),
    radioButtonCheckedBorderRadius: apiStyleMap.get(
      'radio_button_checked_border_radius',
    ),
    radioButtonCheckedSizes: apiStyleMap.get('radio_button_checked_sizes'),
    notRadioCheckedElementsColor: apiStyleMap.get(
      'not_radio_checked_elements_color',
    )?.[0],
    stepsTextUnderlineColor: apiStyleMap.get(
      'steps_text_under_line_color',
    )?.[0],
    stepsTextColor: apiStyleMap.get('steps_text_color')?.[0],
  };
  const submitButtonStyles: ISubmitButtonStylesV2 = {
    default: {
      backgroundColor: apiStyleMap
        .get('send_button_background_color')
        ?.split('|||') ?? ['#000', '#000'],
      color: apiStyleMap.get('send_button_text_color')?.split('|||') ?? [
        '#fff',
        '#fff',
      ],
    },
    disabled: {
      backgroundColor: apiStyleMap
        .get('send_button_disabled_background_color')
        ?.split('|||') ?? ['#000', '#000'],
      color: apiStyleMap
        .get('send_button_disabled_text_color')
        ?.split('|||') ?? ['#fff', '#fff'],
    },
  };
  const fontFamAndUrl = apiStyleMap.get('font_family')?.split('|||');
  const fontStyles: IFontStylesV2 = {
    fontFamily: fontFamAndUrl?.[0],
    fontUrl: fontFamAndUrl?.[1],
    // fontWeight:
    //   apiStyleMap.get('font_weight_bold') === 'true' ? 'bold' : 'normal',
    isFontWeightBold: apiStyleMap.get('font_weight_bold') === 'true',
  };

  return {
    globalStyles,
    fontStyles,
    submitButtonStyles,
    fieldStyles: new Map(),
    formStyles: {
      id: undefined,
      title: undefined,
      sessionId: '',
      name: '',
      logo: '',
      logoLocation: '',
      nodeAddress: '',
      backgroundColor: undefined,
      anonymous: false,
      elementsColor: '',
      nodeCode: undefined,
      sendButtonText: undefined,
      showNodeCode: false,
      companyID: undefined,
      nodeID: undefined,
      googlePlaceId: undefined,
      withStep: false,
    },
  };
}
