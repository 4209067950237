import styled from 'styled-components';

export const PictureContainer = styled.div`
  .pictureWrapper {
    display: flex;
    justify-content: center;

    .pictureContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 21px;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
`;
