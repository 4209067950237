export function questionTextByFormType(
  isPageByPageForm: boolean | undefined,
  index: number,
  question?: string,
) {
  let questionText: string;
  if (!isPageByPageForm) {
    questionText = `${index > 9 ? index : `0${index}`}. ${question}`;
  } else {
    questionText = `${question}`;
  }

  return questionText;
}

export function questionTextV2(answerPositionV2: number, question?: string) {
  return `${
    answerPositionV2 > 9 ? answerPositionV2 : `0${answerPositionV2}`
  }. ${question}`;
}
