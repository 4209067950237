import React, { useEffect, useState } from 'react';
import { NpsScaleStyles } from './NpsScaleStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { NpsScaleProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';
import { IFormStateV2 } from 'pages/form/types';

const valuesAndColors: any = {
  0: '#EB5757',
  1: '#EB5757',
  2: '#EB5757',
  3: '#EB5757',
  4: '#EB5757',
  5: '#EB5757',
  6: '#EB5757',
  7: '#FFC627',
  8: '#FFC627',
  9: '#94D60A',
  10: '#94D60A',
};

export function NpsScale({
  id,
  defaultValue,
  signatures,
  errorName,
  value,
  handleChange,
  scaleValues,
  showDefaultValue,
  signatureColor,
}: NpsScaleProps) {
  const { touched, errors } = useFormikContext<IFormStateV2>();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const [assessment, setAssessment] = useState<number>();
  const scaleValueCondition = value || value === 0;

  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue);
      } else {
        setAssessment(defaultValue);
      }
    }
  }, [defaultValue]);

  const error = errors?.extraFields?.[`${id}|||s`]?.answer;

  return (
    <NpsScaleStyles
      signatureColor={signatureColor}
      isFontWeightBold={isFontWeightBold}
    >
      <div className="buttons" style={{ borderColor: '#E5E5E5' }}>
        {scaleValues?.map((number) => (
          <button
            key={number}
            type="button"
            style={
              (scaleValueCondition ? value === number : assessment === number)
                ? {
                  backgroundColor: valuesAndColors[number],
                  borderColor: valuesAndColors[number],
                }
                : { borderColor: '#E5E5E5' }
            }
            onClick={
              handleChange
                ? () => handleChange(number)
                : () => setAssessment(number)
            }
          >
            <span
              style={
                (scaleValueCondition ? value === number : assessment === number)
                  ? { color: '#fff' }
                  : { color: valuesAndColors[number] }
              }
            >
              {number}
            </span>
          </button>
        ))}
      </div>

      <div className="signatures">
        <span>{signatures[0]}</span>
        <span>{signatures[1]}</span>
      </div>

      <div className="formErrorContainer">
        {touched && (
          <ErrorMessage name={errorName} component={FormErrorMessage} />
        )}
        {error && <p>{error}</p>}
      </div>
    </NpsScaleStyles>
  );
}
