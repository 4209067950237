export function CallIcon({ width = 32, height = 32, color = '#0E9285' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill={color} />
      <g clipPath="url(#clip0_1619_2968)">
        <path
          d="M12.7973 7.86466C12.5266 7.21075 11.8129 6.86271 11.1309 7.04903L8.03711 7.89278C7.42539 8.06153 7 8.617 7 9.24982C7 17.9475 14.0523 24.9998 22.75 24.9998C23.3828 24.9998 23.9383 24.5744 24.107 23.9627L24.9508 20.869C25.1371 20.1869 24.7891 19.4733 24.1352 19.2026L20.7602 17.7963C20.1871 17.5572 19.5227 17.7225 19.1324 18.2041L17.7121 19.9373C15.2371 18.7666 13.2332 16.7627 12.0625 14.2877L13.7957 12.8709C14.2773 12.4772 14.4426 11.8162 14.2035 11.2432L12.7973 7.86818V7.86466Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1619_2968">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
