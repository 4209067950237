import { FormStateV2 } from './types';

export const initialState: FormStateV2 = {
  isLoading: false,
  page: 0,
  localization: [],
  node: undefined,
  formSettings: undefined,
  pagesHistory: [],
  startPage: {},
  isPageByPageForm: false,
};
