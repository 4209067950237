import React from 'react';

type TCloseModalIcon = {
  fill?: string;
  height?: number;
  width?: number;
};

export function CloseModalIcon({
  fill = '#FFBA0A',
  height = 20,
  width = 20,
}: TCloseModalIcon) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="10.5" r="7.5" fill="white" />
      <path
        d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM14.207 12.793L12.793 14.207L10 11.414L7.207 14.207L5.793 12.793L8.586 10L5.793 7.207L7.207 5.793L10 8.586L12.793 5.793L14.207 7.207L11.414 10L14.207 12.793Z"
        fill={fill}
      />
    </svg>
  );
}
