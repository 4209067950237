import BaseApi from '../baseApi/BaseApi';

import { mapFromFileToFormData } from '../mappers';
import { IFilemApi } from './IFileApi';

class FileApi implements IFilemApi {
  baseApi = new BaseApi();

  // eslint-disable-next-line class-methods-use-this
  public async sendFile(file: File): Promise<any> {
    const fileBinary: FormData = mapFromFileToFormData(file);
    try {
      const res = await this.baseApi.postFormData('/file', fileBinary);
      if (res.statusCode >= 200 && res.statusCode < 300) {
        return {
          data: res.data,
          statusCode: res.statusCode,
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Post file error', e);
    }
  }
}

export const fileApi = new FileApi();
